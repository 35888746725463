<template>
  <HeroImage v-if="props?.strapi?.Image" :src="props.strapi.Image.url" :alt="props.strapi.Image.alternativeText">
    <div class="pt-40 pt-sm-50 pt-md-0 hero-content d-flex flex-column justify-center h-100 overlay" :class="swiperActive ? 'active-swiper' : ''" @click="onSwiperClose">
      <div class="container">
        <div class="grid">
          <div class="mb-20 mb-sm-30 mb-md-0 grid-item align-self-center -col-span-12 -col-span-sm-10 -col-span-md-7" :class="swiperActive ? 'opacity-50' : ''" @click.stop>
            <h1 v-if="props.strapi.Heading" class="h1 bold text-white" v-html="props.strapi.Heading" />
            <Button v-if="props.strapi.Button" :href="props.strapi.Button.Href" icon="fa-regular fa-chevron-right" variant="outlined" color="white">
              {{ props.strapi.Button.Label }}
            </Button>
          </div>
          <HomeHeroCarousel v-if="isNotBlank(props.strapi.Highlights)" class="mb-10 mb-md-0" :items="props.strapi.Highlights" :swiperActive="swiperActive" @openActiveSwiper="onSwiperOpen()" @click.stop />
        </div>
      </div>
    </div>
  </HeroImage>
</template>

<script setup>
import { ref } from 'vue';
import HeroImage from '@Components/HeroImage.vue';
import HomeHeroCarousel from './HomeHeroCarousel.vue';
import { isNotBlank } from '@/utils/default';

const props = defineProps({
  strapi: {
    type: Object,
    required: true
  }
});

const swiperActive = ref(false);

function onSwiperOpen() {
  swiperActive.value = true;
}
function onSwiperClose() {
  if (swiperActive.value === true) {
    swiperActive.value = false;
  }
}
</script>

<style lang="scss" scoped>
.hero-content {
  position: relative;
  z-index: 2;
  @include fade-in;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 38.07%), linear-gradient(90deg, rgba(0, 0, 0, 0.43) 0%, rgba(0, 0, 0, 0) 84.95%);
}

.overlay {
  transition: background-color 0.5s ease-in-out; /* Smooth transition for fade-in */

  &.active-swiper {
    @include is-screen-md {
      background-color: rgba(0, 0, 0, 0.7);
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
    }
  }
}
.opacity-50 {
  @include is-screen-md {
    opacity: 0.3;
  }
}
</style>
