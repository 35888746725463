<script setup>
import ArrowSvg from '../svgs/right-arrow.svg';
import { computed, ref } from 'vue';
import { isBlank } from '@/utils/default';

const props = defineProps({
  item: {
    type: Object,
    default: () => ({})
  },
  index: {
    type: Number,
    default: 0
  },
  length: {
    type: Number,
    default: 0
  }
});

const { item } = props;

const getColorClass = (color, prefix) => {
  switch (color) {
    case 'Red':
      return `${prefix}-secondary`;
    case 'Yellow':
      return `${prefix}-primary`;
    case 'Green':
      return `${prefix}-accent`;
    case 'White':
      return `${prefix}-white`;
    case 'Black':
      return `${prefix}-black`;
    default:
      return `${prefix}-white`;
  }
};

const backgroundColor = computed(() => getColorClass(item.BackgroundColor, 'bg'));
const descriptionColor = computed(() => (item.DescriptionColor === 'White' ? 'text-white' : 'text-black'));
const colorSettingsDescription = computed(() => `${backgroundColor.value} ${descriptionColor.value}`);
const titleColor = computed(() => getColorClass(item.TitleColor, 'text'));
const svgColor = computed(() => getColorClass(item.ArrowColor, 'text'));
const svgColorSetting = computed(() => svgColor.value);
const titleBackgroundColor = computed(() => getColorClass(item.TitleBackgroundColor, 'bg-title-color'));
const hasTitleBackgroundColor = computed(() => !(isBlank(item.TitleBackgroundColor) || item.TitleBackgroundColor === 'None'));

const backgroundImage = computed(() => {
  const imageUrl = item?.BackgroundImage?.formats?.small?.url || item?.BackgroundImage?.url || '';
  return imageUrl ? `background-image: url(${imageUrl})` : '';
});
</script>

<template>
  <Card class="grid-item d-flex flex-column -col-span-12 -col-span-sm-6 -col-span-md-3" :class="[colorSettingsDescription]">
    <div class="content" :style="backgroundImage">
      <a :href="item?.Link" class="h-100 w-100" :aria-label="`Link that goes to ${item.LinkLabel}`">
        <div class="h-100 d-flex flex-column align-content-end max-h-390px">
          <div :class="hasTitleBackgroundColor ? `${titleBackgroundColor} mx-n5 mt-n3 mb-1 px-5 py-3 rounded-lg w-auto` : ''">
            <h2 v-if="item.Title" class="-no-margin no-opacity w-100 h4" :class="titleColor" v-html="item.Title" />
          </div>
          <VImg v-if="item?.Image?.url" class="w-100 my-4 flex-shrink-1" aspect-ratio="1/1" :src="item.Image.url" :alt="item.Image.alternativeText" />
          <div class="d-flex flex-grow-1 flex-column align-content-end">
            <div class="flex-grow-1 d-flex align-end">
              <p v-if="item?.Description" class="paragraph mb-0" v-html="item.Description" />
            </div>
            <ArrowSvg v-if="item?.ViewArrow" class="w-50px mt-4" :class="`${svgColorSetting}`" />
          </div>
        </div>
      </a>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.v-card {
  transition:
    transform 0.5s ease-in-out,
    box-shadow 0.5s ease-in-out;

  svg {
    transition: width 0.5s ease-in-out;
  }

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 20px 40px 2px rgba(100, 100, 100, 0.4) !important;

    svg {
      width: 60px !important;
    }
  }
}

.bg-title-color-white {
  background-color: rgba($color-white, 0.4);
}
.bg-title-color-black {
  background-color: rgba($color-black, 0.5);
}
.bg-title-color-primary {
  background-color: rgba($color-primary, 0.4);
}
.bg-title-color-secondary {
  background-color: rgba($color-secondary, 0.4);
}

.grid-item {
  height: 100%;

  .content {
    margin: -1px;
    padding: 24px 32px 32px;
    background-size: cover;
    background-position: center;
    flex-grow: 1;

    @include is-screen-lg {
      padding: 32px 40px 40px;
    }
  }
}

.first-item {
  @include is-screen-md {
    grid-row: span 2;
  }
}
</style>
