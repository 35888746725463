<script setup>
import { computed } from 'vue';
import AddComment from './components/AddComment.vue';
import Files from './components/Files.vue';
import Comments from './components/Comments.vue';
import { useWindowTechship } from '@/composables/useWindowTechship';

const loading = false;
const { data } = useWindowTechship();

const customerCCs = computed(() => {
  return data.ticket.customerCC.map(user => user.name).join(', ');
});
</script>

<template>
  <Card adjustToMenu data-menu-bg="true">
    <template #title>
      <div class="container d-flex flex-column pt-6 pb-6 pt-sm-12 pb-sm-12 bg-white">
        <div class="d-flex">
          <div class="bg-primary w-55px h-55px d-flex rounded-circle justify-center align-center mr-4">
            <VIcon :icon="data.strapi.Icon" size="x-large" />
          </div>
          <h1 class="text-secondary -no-margin">
            {{ data.strapi.Title }}
            <span class="text-grey">{{ data.ticket.id }}</span>
          </h1>
        </div>
        <p class="text-black mt-10 mr-2 fs-24 fs-sm-32">{{ data.ticket.title }}</p>
      </div>
    </template>
    <div class="wrappe py-6 py-lg-20 responsive-background">
      <div class="container grid">
        <!-- Description Section -->
        <Card class="grid-item -col-span-12 -col-span-md-6 pa-md-10">
          <header class="d-flex">
            <VIcon :icon="data.strapi.DescriptionIcon" size="24px" class="mr-3" />
            <h6 class="paragraph-large font-weight-regular">{{ data.strapi.DescriptionTitle }}</h6>
          </header>
          <VDivider class="mt-2 mb-5 mt-md-4 mb-md-10" />
          <div v-markdown="data.ticket.description" />
        </Card>

        <!-- Details Section -->
        <Card class="grid-item -col-span-12 -col-span-md-6 pa-0 pa-md-10">
          <header class="d-flex">
            <VIcon :icon="data.strapi.DetailSummaryIcon" size="24px" class="mr-3" />
            <h6 class="paragraph-large font-weight-regular">{{ data.strapi.DetailSummaryTitle }}</h6>
          </header>
          <VDivider class="mt-2 mb-5 mt-md-4 mb-md-10" />
          <Form :disabled="loading" grid :isEditing="false" columns="1fr 1fr">
            <label>{{ data.strapi.DetailSummary.Title }}</label>
            <p class="-no-margin">{{ data.ticket.title }}</p>

            <label>{{ data.strapi.DetailSummary.Type }}</label>
            <p class="-no-margin">{{ data.ticket.combinedTypeName }}</p>

            <label>Created</label>
            <p class="-no-margin">{{ $formatDateAsDayMonthYear(data.ticket.created_at, 'full') }}</p>

            <label>Updated</label>
            <p class="-no-margin">{{ $formatDateAsDayMonthYear(data.ticket.updated_at, 'full') }}</p>

            <template v-if="data.ticket.article">
              <label>Product</label>
              <a class="underline-primary" :href="data.ticket.article.route.publicShow" target="_blank">{{ data.ticket.article.name }}</a>
            </template>
            <template v-else-if="$isNotBlank(data.ticket.manual_article)">
              <label>Product</label>
              <p class="-no-margin">{{ data.ticket.manual_article }}</p>
            </template>

            <label>{{ data.strapi.DetailSummary.Status }}</label>
            <p class="-no-margin">{{ data.ticket.status }}</p>

            <label>{{ data.strapi.DetailSummary.MailingList }}</label>
            <p class="-no-margin">{{ data.customerCCs }}</p>

            <template v-for="field in data.ticket.values" :key="field.id">
              <label>{{ field.ticketTypeField.label }}</label>
              <p class="-no-margin">{{ field.text }}</p>
            </template>
          </Form>
        </Card>

        <!-- Files Section -->
        <Files v-if="$isNotEmpty(data.ticket.s3files)" v-model="data.ticket.s3files" :strapi="strapi" disableUpload disableRemove />

        <!-- Add Comment Section -->
        <!-- TODO: Should probb use the status id -->
        <AddComment v-if="data.ticket.isOpenStatus" :strapi="strapi" />

        <!-- Comments History -->
        <Comments v-model="data.ticket.comments" :strapi="strapi" />
      </div>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
.responsive-background {
  background-color: white;

  @include is-screen-md {
    background-color: rgb(var(--v-theme-grey-background)) !important;
  }
}
</style>
