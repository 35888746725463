<template>
  <div class="grid align-start">
    <div v-for="(file, index) in files" :key="file" class="grid-item d-flex flex-column align-start w-262px min-h-169px">
      <VImg v-if="file.preview" :src="file.preview" class="rounded-lg" min-height="125" min-width="125" width="125" height="125" cover />
      <div v-else class="w-125px h-125px min-h-125px min-w-125px bg-secondary d-flex align-center justify-center rounded-lg position-relative">
        <Button v-if="!disableDownload" iconButton icon="far fa-download" class="bg-white hover-dark z-index-1" ariaLabel="Download file" iconSize="small" @click="onDowload(file, index, $event)" />
        <Button v-if="!disableRemove" iconButton icon="far fa-trash-can" class="bg-white hover-dark z-index-1" ariaLabel="Remove file" iconSize="small" @click="onRemove(file, index, $event)" />
        <VIcon :icon="file.icon" class="file-icon" size="x-large" />
      </div>
      <div class="d-flex flex-column mt-1 w-100 text-grey-dark over">
        <span v-trim="{ maxLength: 50 }">{{ file.name }}</span>
        <span>{{ $utils.formatFileSize(file.size) }}</span>
      </div>
    </div>
    <Card v-if="!disableUpload" id="dropzone" class="bg-grey-background pa-5 h-262px flex-grow-1 grid-item">
      <div v-if="loading" class="loader-overlay">
        <VProgressCircular indeterminate color="primary" />
      </div>
      <div class="position-relative w-100 h-100 dotted rounded-lg" @dragover.prevent @drop="onDropFileAdd">
        <div class="d-flex flex-column align-center justify-center h-100">
          <VIcon icon="fa-regular fa-cloud-arrow-up" class="text-primary mb-5" size="50px" />
          <label for="dropzoneFileInput">{{ strapi.Description }}</label>
        </div>
        <input id="dropzoneFileInput" ref="fileInput" class="cursor-pointer" type="file" name="files[]" :accept="acceptedFormats" multiple @change="onInputFileAdd" />
      </div>
    </Card>
  </div>
</template>

<script>
import filenamify from 'filenamify/browser';

export default {
  name: 'Dropzone',
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    strapi: {
      type: Object,
      default: () => {
        return {
          Description: 'Click or drop files here to upload'
        };
      }
    },
    allowedExtensions: {
      type: Array,
      default: () => [] // Example: ['pdf', 'jpeg', 'png']
    },
    maxFiles: {
      type: Number,
      default: 5
    },
    endpoint: {
      type: String,
      default: ''
    },
    disableUpload: {
      type: Boolean,
      default: false
    },
    disableRemove: {
      type: Boolean,
      default: false
    },
    disableDownload: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'onFileAdded', 'onFileRemoved'],
  data() {
    return {
      files: this.modelValue,
      loading: false
    };
  },
  computed: {
    hasFiles() {
      return this.$utils.isNotEmpty(this.files);
    },
    acceptedFormats() {
      // Convert the allowedExtensions array to a string for the accept attribute
      return this.allowedExtensions.map(ext => `.${ext}`).join(',');
    }
  },
  watch: {
    modelValue: {
      handler(newFiles) {
        this.files = newFiles;
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onDowload(file, index, event) {
      event.preventDefault();
      if (this.$utils.isBlank(file.temporaryUrl)) {
        this.$snackbar.error('File does not have a download URL');
        return;
      }

      this.$redirect(file.temporaryUrl);
    },
    onInputFileAdd() {
      this.processFiles(this.$refs.fileInput.files);
    },
    onDropFileAdd(event) {
      event.preventDefault();
      this.processFiles(event.dataTransfer.files);
    },
    onRemove(file, index, event) {
      event.preventDefault();
      const newFiles = [...this.files];
      newFiles.splice(index, 1);
      this.updateFiles(newFiles);
      this.$emit('onFileRemoved', file);
    },
    async processFiles(files) {
      this.loading = true;
      const remainingSlots = this.maxFiles - this.files.length;
      const filesToAdd = Array.from(files).slice(0, remainingSlots);

      for (const file of filesToAdd) {
        const fileExtension = file.name.split('.').pop().toLowerCase();
        // Check if the file extension is allowed
        if (this.$utils.isEmpty(this.allowedExtensions) || this.allowedExtensions.includes(fileExtension)) {
          await this.readFile(file);
        } else {
          this.$snackbar.warning(`File extension not allowed: ${fileExtension}`);
        }
      }

      if (files.length > remainingSlots) {
        this.$snackbar.warning(`You can only upload a maximum of ${this.maxFiles} files.`);
      }

      this.loading = false;
    },
    async readFile(file) {
      return new Promise(async resolve => {
        const isImageType = this.$utils.isImage(file.type);
        let fileData = {
          // raw: file,
          name: filenamify(file.name, { replacement: '-' }),
          size: file.size,
          type: file.type,
          preview: null, // Set default preview to null
          icon: null // Set default icon to null
        };

        // For local preview/icon before uploading
        if (isImageType) {
          const reader = new FileReader();
          reader.onload = event => {
            fileData.preview = event.target.result; // Set preview for image files
          };
          reader.readAsDataURL(file);
        } else {
          fileData.icon = this.$utils.getFileIcon(file.type); // Set icon for non-image files
        }

        if (this.$utils.isBlank(this.endpoint)) {
          // If no endpoint, directly add the file
          this.addFileAndResolve(fileData, resolve);
        } else {
          // If there is an endpoint, upload the file
          const formData = new FormData();
          formData.append('file', file);

          const response = await this.post(this.endpoint, formData);
          if (!response) {
            this.$snackbar.error(`Error uploading file: ${file.name}`);
          }
          // Update fileData based on the response
          fileData = { ...fileData, ...response.file };

          this.addFileAndResolve(fileData, resolve);
        }
      });
    },
    addFileAndResolve(fileData, resolve) {
      setTimeout(() => {
        const newFiles = [...this.files, fileData];
        this.updateFiles(newFiles);
        this.$emit('onFileAdded', fileData);
        resolve();
      }, 200);
    },
    updateFiles(newFiles) {
      this.files = newFiles;
      this.$emit('update:modelValue', newFiles);
    },
    generateFormData() {
      const formData = new FormData();
      this.$utils.each(this.files, file => {
        formData.append('files[]', file.raw);
      });
      return formData;
    }
  }
};
</script>

<style lang="scss" scoped>
.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  transition: height 0.2s ease-in-out;
  padding: 0;
  position: relative;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 40px;
}

input[type='file'] {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.dotted {
  border: 2px dashed $color-grey;
}

button {
  position: absolute !important;
  top: 5px;
  &:first-child {
    right: 5px;
  }

  &:last-of-type {
    right: 84px;
  }
}

input[type='file'] {
  opacity: 0;
}

.dropzone {
  padding: 20px;
  text-align: center;
  transition: height 0.2s ease-in-out;
  height: 300px;
}

.img-info {
  text-align: start;
}

.trash-can-icon {
  position: relative;
  top: 12%;
  left: 85%;
}
.image-preview {
  margin: 10px;
  display: inline-block;
  max-width: 100%;
  height: auto;
}
.image-preview img {
  width: 260px;
  height: 260px;
}
</style>
