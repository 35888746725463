<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay, Mousewheel } from 'swiper/modules';
import HighlightHeroItem from '@Components/HighlightHeroItem.vue';

const modules = [Pagination, Autoplay, Mousewheel];
const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  swiperActive: {
    type: Boolean,
    default: true
  }
});

const emit = defineEmits(['openActiveSwiper']);

// Variable to store Swiper instance
let swiperInstance = null;
// Get the swiper instance when it's ready
const onSwiperInit = swiper => {
  swiperInstance = swiper;
};
const goToPrev = () => {
  if (swiperInstance) {
    openActiveSwiper();
    swiperInstance.slidePrev();
  }
};
const goToNext = () => {
  if (swiperInstance) {
    openActiveSwiper();
    swiperInstance.slideNext();
  }
};

function getSwiperItems() {
  if (props?.items?.length <= 3) {
    return props.items.concat(props.items);
  }
  return props?.items;
}

function openActiveSwiper() {
  emit('openActiveSwiper');
}
</script>

<template>
  <div class="grid-item -col-span-12 -col-span-md-5">
    <template v-if="props?.items?.length === 1">
      <HighlightHeroItem :index="0" :item="props.items[0]" class="h-420px w-300px ml-sm-auto" />
    </template>
    <template v-else>
      <Swiper
        :modules="modules"
        grabCursor
        :slidesOffsetBefore="10"
        :spaceBetween="16"
        :slides-per-view="'auto'"
        :breakpoints="{
          600: { spaceBetween: 20, slidesOffsetBefore: 15 },
          960: { spaceBetween: 34, slidesOffsetBefore: 20 },
          1280: { spaceBetween: 40, slidesOffsetBefore: 20 }
        }"
        :loop="true"
        class="ml-n3 mr-n6 ml-sm-n2 mr-md-n10 mr-lg-n5 ml-sm-n5 pl-1 pl-md-3 pr-5 carousel-wrapper"
        :class="swiperActive ? 'ml-lg-n100 ml-md-n90' : ''"
        :style="{ overflow: 'hidden' }"
        :pagination="{ el: '.swiper-pagination', type: 'bullets', clickable: true }"
        :mousewheel="{ enabled: true }"
        :autoplay="{ delay: 3500, pauseOnMouseEnter: true, disableOnInteraction: true }"
        @swiper="onSwiperInit"
      >
        <SwiperSlide v-for="(item, index) in getSwiperItems()" :key="item.id" class="swiper-width-transition h-420px w-300px">
          <HighlightHeroItem :index="index" :item="item" class="" />
        </SwiperSlide>
        <VContainer class="pl-0 ml-5 pr-5 pr-md-10 pr-lg-5">
          <VRow align="center" justify="center">
            <VCol class="swiper-pagination w-100 h-2px" cols="12" sm="10" md="9" lg="10" />
            <VCol class="d-none d-sm-flex ga-2 justify-end" cols="2" md="3" lg="2">
              <div class="circle pa-1" @click="goToPrev"><VIcon icon="far fa-arrow-left" size="medium" class="circle-icon mx-auto" /></div>
              <div class="circle pa-1" @click="goToNext"><VIcon icon="far fa-arrow-right" size="medium" class="circle-icon mx-auto" /></div>
            </VCol>
          </VRow>
        </VContainer>
      </Swiper>
    </template>
  </div>
</template>
<style lang="scss" scoped>
:deep() {
  @import 'swiper/scss';
  @import 'swiper/scss/autoplay';
}

.swiper-pagination {
  display: inline-flex;

  :global(.swiper-pagination-bullet) {
    display: flex;
    height: 2px;
    background: $color-white;
    opacity: 0.4;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    transition: flex-grow 0.4s ease-in-out;
  }

  :global(.swiper-pagination-bullet-active) {
    display: flex;
    height: 2px;
    background: $color-white;
    flex-grow: 3;
    opacity: 1;
    justify-content: center;
    align-items: center;
  }
}

.carousel-wrapper {
  position: relative;
  overflow: hidden;
  mask-image: linear-gradient(90deg, transparent 0%, black 4%);
  -webkit-mask-image: linear-gradient(90deg, transparent 0%, black 4%);

  @include is-screen-sm {
    mask-image: linear-gradient(90deg, transparent 0%, black 1%);
    -webkit-mask-image: linear-gradient(90deg, transparent 0%, black 1%);
  }

  @include is-screen-md {
    mask-image: linear-gradient(90deg, transparent 0%, black 6%);
    -webkit-mask-image: linear-gradient(90deg, transparent 0%, black 6%);
  }

  @include is-screen-lg {
    mask-image: linear-gradient(90deg, transparent 0%, black 4%, black 96%, transparent 100%);
    -webkit-mask-image: linear-gradient(90deg, transparent 0%, black 4%, black 96%, transparent 100%);
  }
  z-index: 10;
}

.swiper-width-transition {
  transition: width 0.5s ease-in-out;
}

.circle {
  display: flex; /* Ensures the children are laid out in a flexbox */
  justify-content: center; /* Centers the icon horizontally */
  align-items: center;
  border-color: $color-white;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  color: $color-white;
  &:hover {
    background-color: $color-white;
    border-color: $color-white;

    .circle-icon {
      color: $color-secondary;
    }
  }
}
</style>
