<template>
  <Card class="grid-item -col-span-12 py-5 px-0 px-md-5">
    <header class="d-flex">
      <VIcon :icon="strapi.CommentsHistoryIcon" size="24px" class="mr-3 ml-1" />
      <h6 class="paragraph-large font-weight-regular">{{ strapi.CommentsHistoryTitle }}</h6>
    </header>
    <template v-for="comment in comments" :key="comment.id">
      <VDivider class="mt-2 mb-5 mt-md-4 mb-md-10" />
      <Form class="d-flex flex-column grid-md grid-template-columns: 250px auto" :isEditing="false" gap="40px">
        <div class="d-flex flex-column flex-md-row">
          <div>
            <div class="py-4 mx-md-4">
              <span class="text-caption d-flex justify-start">
                {{ comment.commenter.name }}
                <VImg v-if="strapi.TechshipAdminCommentIcon?.url && comment.commenter_type == 'Admin'" :src="strapi.TechshipAdminCommentIcon?.url" :alt="strapi.TechshipAdminCommentIcon?.alternativeText" class="mx-2 h-20px w-20px max-w-20px rounded-circle" />
                {{ $utils.formatDateAsDayMonthYear(comment.updated_at, 'full') }}
              </span>
              <div v-markdown="comment.body" class="rounded-lg mt-4" />
            </div>
            <Dropzone :model-value="comment.s3Files" disableUpload disableRemove />
          </div>
        </div>
      </Form>
    </template>
  </Card>
</template>

<script>
import Dropzone from '@Components/Dropzone.vue';

export default {
  name: 'Comments',
  components: { Dropzone },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    strapi: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      comments: this.modelValue
    };
  }
};
</script>

<style lang="scss" scoped></style>
